<template>
  <DxDataGrid
    ref="dataGrid"
    class="sxr-grid caption-left regular-text-color"
    :data-source="datasource"
    :show-borders="false"
    :show-row-lines="true"
    :show-column-lines="false"
    :remote-operations="true"
    :word-wrap-enabled="true"
    :column-min-width="50"
    :column-auto-width="true"
    :allow-column-reordering="!isCurrentBreakPointXs"
    width="100%"
    height="100%"
  >
    <DxHeaderFilter
      :visible="false"
      :hide-select-all-on-search="false"
    />
    <DxPaging :page-size="25" />
    <DxColumnChooser
      :enabled="!isCurrentBreakPointXs"
      mode="select"
    />
    <DxExport
      :enabled="false"
      :formats="['csv', 'xlsx']"
      :texts="{
        exportAll: 'Export to {0}'
      }"
    />
    <DxScrolling
      mode="virtual"
    />
    <DxStateStoring
      :enabled="false"
      type="localStorage"
      storage-key="users_dg_state_key_1"
    />
    <DxSearchPanel
      :visible="true"
      :highlight-case-sensitive="true"
    />
    <DxToolbar>
      <DxItem
        location="before"
        name="searchPanel"
      />
      <DxItem
        name="columnChooserButton"
      />
      <DxItem
        name="exportButton"
      />
    </DxToolbar>
    <DxColumn
      data-field="user"
      caption="User"
      sort-order="asc"
      :min-width="250"
      cell-template="userCellTemplate"
    />
    <DxColumn
      data-field="role"
      caption="Role"
      :min-width="90"
      cell-template="roleCellTemplate"
    />
    <DxColumn
      data-field="subscription_plan"
      caption="Plan"
      :min-width="160"
      cell-template="planCellTemplate"
    />
    <DxColumn
      data-field="status"
      caption="Status"
      :min-width="80"
      cell-template="statusCellTemplate"
    />
    <DxColumn
      data-field="shop_connected"
      caption="Shop Connected"
      :min-width="180"
      data-type="boolean"
    />
    <DxColumn
      data-field="created_at"
      caption="Created At"
      :min-width="160"
      data-type="datetime"
    />
    <DxColumn
      :show-in-column-chooser="false"
      :fixed="true"
      fixed-position="right"
      alignment="center"
      css-class="fixed-column-cell"
      type="buttons"
      width="70"
      cell-template="actionsCellTemplate"
    />
    <template #planCellTemplate="{ data: cell }">
      <h6 class="m-0">
        {{ cell.data.subscription_plan }}
      </h6>
    </template>
    <template #userCellTemplate="{ data: cell }">
      <UserCell :value="cell.data" />
    </template>
    <template #roleCellTemplate="{ data: cell }">
      <RoleCell :value="cell.data.role" />
    </template>
    <template #statusCellTemplate="{ data: cell }">
      <StatusCell :value="cell.data.status" />
    </template>
    <template #actionsCellTemplate="{ data: cell }">
      <DxDropDownButton
        :split-button="false"
        :focus-state-enabled="false"
        :show-arrow-icon="false"
        :use-select-mode="false"
        :drop-down-options="{ width: 300 }"
        :items="actions(cell)"
        display-expr="text"
        icon="overflow"
        width="34"
        @item-click="onActionClick"
      />
    </template>
  </DxDataGrid>
</template>

<script>
import {
  DxColumn,
  DxDataGrid,
  DxPaging,
  DxHeaderFilter,
  DxColumnChooser,
  DxToolbar,
  DxItem,
  DxScrolling,
  DxStateStoring,
  DxExport,
  DxSearchPanel,
} from 'devextreme-vue/data-grid'
import { DxDropDownButton } from 'devextreme-vue/drop-down-button'
import userDatasource from '@/data/user.datasource'
import UserCell from '@/views/admin/users/components/UserCell.vue'
import RoleCell from '@/views/admin/users/components/RoleCell.vue'
import StatusCell from '@/views/admin/users/components/StatusCell.vue'

export default {
  name: 'UsersDataGrid',
  components: {
    StatusCell,
    RoleCell,
    UserCell,
    DxColumn,
    DxDataGrid,
    DxPaging,
    DxHeaderFilter,
    DxColumnChooser,
    DxToolbar,
    DxItem,
    DxScrolling,
    DxStateStoring,
    DxExport,
    DxSearchPanel,
    DxDropDownButton,
  },
  data() {
    return {
      datasource: userDatasource.getInstance(this.$store),
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    actions({ data }) {
      return [
        {
          id: data.id,
          text: 'Send Shop Not Connected Remainder',
          action: 'send-shop-not-connected-remainder',
          icon: 'email',
          disabled: data.shop_connected,
        },
      ]
    },
    onActionClick({ itemData }) {
      switch (itemData.action) {
        case 'send-shop-not-connected-remainder':
          this.sendShopNotConnectedRemainder(itemData.id)
          break
        default:
      }
    },
    sendShopNotConnectedRemainder(id) {
      this.$store.dispatch('user/sendShopNotConnectedReminder', { userId: id }).then(data => {
        this.$bvToast.toast(data, {
          title: 'Success',
          toaster: 'b-toaster-top-center',
          variant: 'success',
          solid: true,
          appendToast: false,
        })

        this.$refs.dataGrid.instance.refresh()
      }).catch(() => {
        this.$bvToast.toast('Something went wrong. Please try again', {
          title: 'Error',
          toaster: 'b-toaster-top-center',
          variant: 'danger',
          solid: true,
          appendToast: false,
        })
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@/assets/scss/common/grid.scss';
  .dark-layout .dx-popup-wrapper > .dx-overlay-content {
    border: 1px solid #404656;
    background: #353d55;
  }
</style>

<style scoped>

</style>
