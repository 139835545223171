<template>
  <div class="d-flex align-center p-25">
    <b-avatar
      :title="userHint"
      :src="value.avatar"
      variant="light-primary"
      badge
      :badge-variant="badgeVariant"
    >
      <template v-if="isEmpty(value.avatar)">
        {{ avatarText(value.name || 'Shop Owner') }}
      </template>
    </b-avatar>
    <div class="d-flex flex-column ml-25 p-25">
      <h6 class="text-base m-0">
        {{ value.name || 'Shop Owner' }}
      </h6>
      <small class="text-secondary">{{ value.email }}</small>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  name: 'UserCell',
  components: {
    BAvatar,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      avatarText,
    }
  },
  computed: {
    badgeVariant() {
      return this.value.email_confirmed ? 'success' : 'danger'
    },
    userHint() {
      return this.value.email_confirmed ? 'Email confirmed' : 'Email has not been confirmed'
    },
  },
  methods: {
    isEmpty(value) {
      return value === undefined || value === null || value.length === 0
    },
  },
}
</script>

<style scoped>

</style>
