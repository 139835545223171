import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'

export default class {
  static getInstance(store, list = false) {
    return new DataSource({
      store: new CustomStore({
        load: options => {
          const payload = {}
          if (options.searchValue) {
            payload.search = options.searchValue
          }
          if (options.filter && options.filter[0]) {
            // eslint-disable-next-line no-unused-vars
            const [field, operation, value] = options.filter[0]
            payload.search = value
          }
          if (options.sort && options.sort[0]) {
            const mapping = {
              user: 'user',
              created_at: 'created_at',
              role: 'role',
              subscription_plan: 'subscription_plan',
              status: 'status',
              shop_connected: 'shop_connected',
            }
            payload.sort_by = mapping[options.sort[0].selector] ?? options.sort[0].selector
            payload.sort_direction = options.sort[0].desc ? 'DESC' : 'ASC'
          }

          payload.take = options.take
          payload.skip = options.skip ?? 0

          if (list) {
            return store.dispatch('user/fetchList', payload).then(result => {
              if (!result.data) {
                return []
              }

              return {
                data: result.data,
                totalCount: result.total_items,
              }
            })
          }

          return store.dispatch('user/find', payload).then(result => {
            if (!result.data) {
              return []
            }

            return {
              data: result.data,
              totalCount: result.total_items,
            }
          })
        },
      }),
      paginate: true,
      pageSize: 25,
    })
  }
}
