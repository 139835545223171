var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxDataGrid',{ref:"dataGrid",staticClass:"sxr-grid caption-left regular-text-color",attrs:{"data-source":_vm.datasource,"show-borders":false,"show-row-lines":true,"show-column-lines":false,"remote-operations":true,"word-wrap-enabled":true,"column-min-width":50,"column-auto-width":true,"allow-column-reordering":!_vm.isCurrentBreakPointXs,"width":"100%","height":"100%"},scopedSlots:_vm._u([{key:"planCellTemplate",fn:function(ref){
var cell = ref.data;
return [_c('h6',{staticClass:"m-0"},[_vm._v(" "+_vm._s(cell.data.subscription_plan)+" ")])]}},{key:"userCellTemplate",fn:function(ref){
var cell = ref.data;
return [_c('UserCell',{attrs:{"value":cell.data}})]}},{key:"roleCellTemplate",fn:function(ref){
var cell = ref.data;
return [_c('RoleCell',{attrs:{"value":cell.data.role}})]}},{key:"statusCellTemplate",fn:function(ref){
var cell = ref.data;
return [_c('StatusCell',{attrs:{"value":cell.data.status}})]}},{key:"actionsCellTemplate",fn:function(ref){
var cell = ref.data;
return [_c('DxDropDownButton',{attrs:{"split-button":false,"focus-state-enabled":false,"show-arrow-icon":false,"use-select-mode":false,"drop-down-options":{ width: 300 },"items":_vm.actions(cell),"display-expr":"text","icon":"overflow","width":"34"},on:{"item-click":_vm.onActionClick}})]}}])},[_c('DxHeaderFilter',{attrs:{"visible":false,"hide-select-all-on-search":false}}),_c('DxPaging',{attrs:{"page-size":25}}),_c('DxColumnChooser',{attrs:{"enabled":!_vm.isCurrentBreakPointXs,"mode":"select"}}),_c('DxExport',{attrs:{"enabled":false,"formats":['csv', 'xlsx'],"texts":{
      exportAll: 'Export to {0}'
    }}}),_c('DxScrolling',{attrs:{"mode":"virtual"}}),_c('DxStateStoring',{attrs:{"enabled":false,"type":"localStorage","storage-key":"users_dg_state_key_1"}}),_c('DxSearchPanel',{attrs:{"visible":true,"highlight-case-sensitive":true}}),_c('DxToolbar',[_c('DxItem',{attrs:{"location":"before","name":"searchPanel"}}),_c('DxItem',{attrs:{"name":"columnChooserButton"}}),_c('DxItem',{attrs:{"name":"exportButton"}})],1),_c('DxColumn',{attrs:{"data-field":"user","caption":"User","sort-order":"asc","min-width":250,"cell-template":"userCellTemplate"}}),_c('DxColumn',{attrs:{"data-field":"role","caption":"Role","min-width":90,"cell-template":"roleCellTemplate"}}),_c('DxColumn',{attrs:{"data-field":"subscription_plan","caption":"Plan","min-width":160,"cell-template":"planCellTemplate"}}),_c('DxColumn',{attrs:{"data-field":"status","caption":"Status","min-width":80,"cell-template":"statusCellTemplate"}}),_c('DxColumn',{attrs:{"data-field":"shop_connected","caption":"Shop Connected","min-width":180,"data-type":"boolean"}}),_c('DxColumn',{attrs:{"data-field":"created_at","caption":"Created At","min-width":160,"data-type":"datetime"}}),_c('DxColumn',{attrs:{"show-in-column-chooser":false,"fixed":true,"fixed-position":"right","alignment":"center","css-class":"fixed-column-cell","type":"buttons","width":"70","cell-template":"actionsCellTemplate"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }