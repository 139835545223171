<template>
  <div class="d-flex align-center align-items-center">
    <b-avatar
      size="2rem"
      :variant="variant"
    >
      <feather-icon :icon="icon" />
    </b-avatar>
    <h6 class="text-capitalize text-base ml-25 mb-0">
      {{ title(value) }}
    </h6>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import { title } from '@core/utils/filter'

export default {
  name: 'RoleCell',
  components: {
    BAvatar,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      title,
    }
  },
  computed: {
    variant() {
      return this.isInternal() ? 'light-secondary' : 'light-warning'
    },
    icon() {
      return this.isInternal() ? 'MonitorIcon' : 'UserIcon'
    },
  },
  methods: {
    isInternal() {
      return ['admin', 'root'].includes(this.value)
    },
  },
}
</script>

<style scoped>

</style>
