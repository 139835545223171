<template>
  <UsersDataGrid />
</template>

<script>
import UsersDataGrid from '@/views/admin/users/components/UsersDataGrid.vue'

export default {
  name: 'ManageUsers',
  components: {
    UsersDataGrid,
  },
}
</script>

<style scoped>

</style>
